import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo.svg";
import close_icon from "./images/icon-close.svg";
import Camera_icon from "./images/Camera_icon.svg";
import { useParams } from "react-router-dom";
import styles from "./styles/homepageStyles";
import convertBase64ToImageFile from './utils/imageUtils.js';
import './styles/spinner.css'
import ImageCapture from "./ImageCapture";
import { v4 as uuidv4 } from 'uuid';

const ScanTicketsScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false)
  const [errorMsgStr, setErrorMsgStr] = useState("")
  const [images, setImages] = useState([]);
  const [httpStatusCode, setHttpStatusCode] = useState(200)
  const [isLoading, setIsLoading] = useState(true)
  const [isIOS,setIsIOS] = useState(false)
  const fileInputRef = useRef(null);
  const divPopupId = "popupModalId"
  const audio = new Audio('/notification.wav');
  const apiUrl = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();
  const { uuid } = useParams();
  const [ groupId, setGroupId ] = useState("");
  const re = /(?:\.([^.]+))?$/;

  const sendImages = async () => {
    // timestamp of the batch
    let timestamp = Date.now().toString()
    const uploadPromises = images.map(async (image, index) => {
      let file = image.file;

      // extension of the source file
      let extension = re.exec(file.name)[1];

      // Generified fileName that uses index of the image in the batch, timestamp of the batch and extension
      let fileName = `Ticket_${index}_${timestamp}.${extension}`;
      try {
        const generateScanLinkResponse = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            groupId,
            fileName: fileName,
            uuid: uuid
          }) // Use groupId from state
        });

        const scanLinkData = await generateScanLinkResponse.json();
        const scanLinkUrl = scanLinkData.body; // Adjust according to your API response
        const response = await fetch(scanLinkUrl, {
          method: 'PUT',
          body: file
        });
        return {
          status: response.status,
          fileName: fileName
        };
      } catch (error) {
        console.error(`catching error: ${error.toString()}`);
        return {
          status: 500,
          detail: error.message,
          fileName: fileName
        };
      }
    })
    const results = await Promise.all(uploadPromises);
    console.log('All uploads completed:', results);
    let filter = results.filter(result => result.status === 500);
    if (filter.length > 0) {
      let fileNames = filter.map(result => result.fileName).join(",");
      return {
        status: 500,
        detail: `Following files were not uploaded: ${fileNames}`
      }
    }
    return {
      status: 200
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scanLinkUrl = `${apiUrl}/${uuid}`;
        console.log(`lancellot ocr api url: ${scanLinkUrl}`);
        const response = await fetch(scanLinkUrl);
        const respData = await response.json();
        setIsLoading(false);
        if (response.status !== 200) {
          setIsError(true);
          setErrorMsgStr(respData.detail);
          setHttpStatusCode(response.status);
        } else if (respData.data.scanLink.active !== true) {
          setErrorMsgStr("Scan link is not activated");
          setIsError(true);
          setHttpStatusCode("");
        } else {
          setGroupId(respData.data.group.groupId); // Set the groupId using state
          console.log('Fetched respData:', respData);
        }
      } catch (error) {
        setIsError(true);
        setErrorMsgStr(error.toString());
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      // Cleanup logic if needed
    };
  }, [uuid]);


  useEffect(() => {
    if (showPopup) {
      focusPopup();
    }
  }, [showPopup]);

  const handleCaptureCamera = (imageSrc) => {
    console.log("handle capture camera called")
    if (imageSrc) {
        const file = convertBase64ToImageFile(imageSrc)
        setImages((prevImages) => [...prevImages, { id: uuidv4(), file }]);
    }
    audio.play();
};

  const handleUploadFromStorageClick = (event) => {
    console.log("handle capture camera called")
    const files = event.target.files;
    if (files) {
        setImages((prevImages) => [...prevImages, ...Array.from(files).map(file => ({ id: uuidv4(), file }))]);
    }
    audio.play();
};

  const handleCapture = (event) => {
    console.log("handle capture called")
    //const file = event.target.files[0];
    const file = convertBase64ToImageFile(event.target.files[0])
    /* if (file && file.type.match("image.*")) */
    if (file) {
      setImages((prevImages) => [...prevImages, file]);
    }
};

const handleRemoveImage = (imageToRemove) => {
  setImages(images.filter((image) => image.id !== imageToRemove.id));
};

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleScanClick = () => {
    // Logic to handle the scanning of tickets
    fileInputRef.current.click();
  };

  function saveStatusInLocalStorage(statusJson) {
    const statusString = JSON.stringify(statusJson);
    localStorage.setItem('status', statusString);
}

  const handleConfirmPopup = async () => {
    console.log("confirm clicked")
    setIsLoading(true)
    const statusJson = await sendImages()
    saveStatusInLocalStorage(statusJson)
    setIsLoading(false)
    navigate("/completed"); // Navigate to the CompletedPage
  };

  const handleGoBackPopup = () => {
    toggleModal();
    setShowPopup(false);
    // Logic to allow the user to go back or edit the ticket information
  };

  const handleConfirmClick = () => {
    // Logic to confirm the scanning
    toggleModal();
    setShowPopup(true); // Show the popup
    //focusPopup()
  };

  const focusPopup = () => {
    const popupDiv = document.getElementById(divPopupId);

    // Scroll to the div:
    popupDiv.scrollIntoView({
      behavior: "smooth", // Smooth scrolling
      block: "center",   // Center the element vertically within the viewport
      inline: "center"   // Center the element horizontally
    });
  }

  return (
    <div>
      {isLoading &&
      <div className="loading-spinner"/>}
      {(!isError && !isLoading) && (
        <>
          <img style={styles.logo} src={logo} alt="Icon" />
          <div style={styles.container}>
            <div
              className={`backdrop ${showModal ? "show" : ""}`}
              onClick={toggleModal}
            ></div>
            <div className={`modal ${showModal ? "show" : ""}`} ></div>
            {showPopup && (
              <div style={styles.popupOverlay} id={divPopupId}>
                <h2 style={styles.header}>Are you sure?</h2>
                <p style={styles.popUpContent}>
                  Please note that clicking 'Confirm' verifies that you have
                  completed the ticket check. Once confirmed, you won't be able to
                  go back or edit the ticket information.
                </p>
                <button style={styles.button2} onClick={handleConfirmPopup}>
                  CONFIRM
                </button>
                <button style={styles.button} onClick={handleGoBackPopup}>
                  GO BACK
                </button>
              </div>
            )}
            {!isIOS && <>
              <h1 style={styles.header}>Scan Tickets!</h1>
            <h2 style={styles.paragraph2}>Please follow the steps:</h2>
            <ol style={styles.paragraph}>
              <li>Double-check: All requested lines bought? Great!</li>
              <li>Gently hold your camera steady and capture the whole ticket.</li>
              <li>Done scanning all the tickets? Tap “Confirm” and you're done!</li>
            </ol>
            <ImageCapture handleCapture={handleCaptureCamera}/>
            </>}

                <input
                type="file"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                id="upload-button"
                onChange={handleUploadFromStorageClick}
            />
            <button
                style={styles.button}
                onClick={() => document.getElementById('upload-button').click()}
            >
                <img src={Camera_icon} alt="Icon" /> Upload from storage
            </button>
            <button style={styles.button2} onClick={handleConfirmClick}>
              Confirm
            </button>
          </div>
          {/* <input
            type="file"
            accept="image/*"
            capture="user"
            style={{ display: "none" }}
            onChange={handleCapture}
            ref={fileInputRef}
          /> */}
          <div className="image-previews">
          {images.map((image, index) => (
            <div key={image.id} className="image-preview">
                <button onClick={() => handleRemoveImage(image)}>
                    <img className="close_icon" src={close_icon} alt="Icon" />
                </button>
                <img
                    src={URL.createObjectURL(image.file)}
                    alt={`Preview ${index}`}
                    style={{ width: "69.16px", height: "182px" }}
                />
            </div>
        ))}
          </div>
        </>
      )}
      {isError && (
        <>
        <div>
          <h1>{httpStatusCode} ERROR</h1>
          <h2>{errorMsgStr}</h2>
        </div>
        </>
      )}
    </div>
  );
};

export default ScanTicketsScreen;
