// Styles object to keep the JSX clean
const styles = {
    logo: {
      display: "block",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    marginTop: "15%",
    margin: "auto"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "left",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      border: "1px solid rgba(188, 177, 197, 0.3)",
      background: "#fcfbfe",
      marginLeft: "5%",
      marginRight: "5%",
    },
    header: {
      fontFamily: "Campton",
      fontSize: "24px",
      fontstyle: "normal",
      fontWight: "600",
      lineHeight: "22px" /* 137.5% */,
      color: "#6823D1",
    },
    paragraph: {
      fontFamily: "Campton",
      fontSize: "16px",
      fontstyle: "normal",
      fontWeight: "400",
      lineHeight: "22px" /* 137.5% */,
      marginBotton: "10%",
    },
    paragraph2: {
      textAlign: "left",
      fontFamily: "Campton",
      fontSize: "16px",
      fontstyle: "normal",
      fontWeight: "500",
      lineHeight: "22px" /* 137.5% */,
      marginBotton: "10%",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#6823D1",
      fontFamily: "Campton",
      color: "white",
      border: "none",
      borderRadius: "20px",
      cursor: "pointer",
      width: "305px",
      height: "56px",
      fontSize: "16px",
      fontstyle: "normal",
      fontWeight: "400",
      lineHeight: "22px" /* 137.5% */,
      marginBotton: "10%",
      marginTop: "5%",
    },
    button2: {
      padding: "10px 20px",
      background: "#fcfbfe",
      fontFamily: "Campton",
      color: "#6823d1",
      border: "1px solid rgba(188, 177, 197, 0.3)",
      borderRadius: "20px",
      cursor: "pointer",
      width: "305px",
      height: "56px",
      fontSize: "16px",
      fontstyle: "normal",
      fontWeight: "400",
      lineHeight: "22px" /* 137.5% */,
      marginBotton: "10%",
      marginTop: "5%",
    },
    popupOverlay: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "left",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      border: "1px solid rgba(188, 177, 197, 0.3)",
      background: "#fcfbfe",
      marginLeft: "5%",
      marginRight: "5%",
      zIndex: "1000",
    },
    popUpContent: {
      fontFamily: "Campton",
      fontSize: "16px",
      fontstyle: "normal",
      fontWeight: "400",
      lineHeight: "22px" /* 137.5% */,
      marginBotton: "10%",
      textAlign: "center",
    },
    flexDiv: {
      display: "flex",
      flexDirection: "column",
      // Added to ensure flex items wrap properly:
      alignItems: "center", // or "stretch" for full button width
      width:"100%"
    },

  };
  export default styles;
