function convertBase64ToImageFile(imageSrc) {
    // Convert base64 to raw binary data
    const byteString = atob(imageSrc.split(',')[1]);

    // Separate out the mime component
    const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0]

    // Write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob from the ArrayBuffer
    const blob = new Blob([arrayBuffer], {type: mimeString});

    // Create a file from the blob
    const file = new File([blob], "capturedImage.jpg", {type: blob.type});

    return file;
}

export default convertBase64ToImageFile