import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import styles from "./styles/homepageStyles";
import Camera_icon from "./images/Camera_icon.svg";
const ImageCapture = ({handleCapture}) => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [error, setError] = useState(null);
  const videoConstraints = {
    facingMode: { exact: "environment" }
  };
  const capture = async () => {
    try {
      const imageSrc = await webcamRef.current.getScreenshot();
      //setImageSrc(imageSrc);
      handleCapture(imageSrc)
      setError(null);
    } catch (error) {
      console.error('Error capturing image:', error);
      setError('Failed to capture image. Please check camera permissions.');
    }
  };

  useEffect(() => {
    // Request camera permissions (for mobile devices)

    
if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => console.log('Camera access granted'))
        .catch((error) => {
          console.error('Camera access denied:', error);
          setError('Camera access is required to capture images.');
        });
    }
  }, []);

  return (
    <div style={styles.flexDiv}>
      <Webcam
        ref={webcamRef}
        audio={false}
        videoConstraints={videoConstraints}
        screenshotQuality={1}
        style={{
            width: '100%',
            height: '100%',
            maxWidth: '320px',
            maxHeight: '280px'
          }}
      />
      {/* <button onClick={capture}>Capture Photo</button> */}
      <button style={styles.button} onClick={capture}>
              <img src={Camera_icon} alt="Icon" /> Scan Ticket
            </button>
      {imageSrc && <img src={imageSrc} alt="Captured Image" />}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default ImageCapture;