import React from "react";
import Whatsapp_icon from "./images/Whatsapp_icon.svg";
import logo from "./images/logo.svg";


function getStatusJsonFromLocalStorage() {
  const statusString = localStorage.getItem('status');
  const statusJson = JSON.parse(statusString);

  console.log(statusJson);
  return statusJson
}

const CompletedScreen = () => {
  const statusJson = getStatusJsonFromLocalStorage();
  return (
    <div>
      <img style={styles.logo} src={logo} alt="Icon" />

      <div style={styles.container}>
        <h1 style={styles.header}>Scan completed!</h1>
        <p style={styles.paragraph}>
          {statusJson && statusJson.status !== 200 ? `Error: ${statusJson.detail}. Status code:${statusJson.status}` :
          'Thank you for your excellent service! The ticket scan has been completed successfully. 👍'}
        </p>
        <p style={styles.paragraph2}>
          Now, please update your dispatch about the ticket scan.
        </p>
        <button style={styles.button} onClick={openWhatsApp}>
          <img src={Whatsapp_icon} alt="Icon" /> Update Dispatch
        </button>
      </div>
    </div>
  );
};
const openWhatsApp = () => {
  // Logic to open WhatsApp with pre-filled message
  const phone = "+12792075019";
  const message = encodeURIComponent(
    "Hi! I've successfully completed the ticket scanning."
  );
  window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
};

// Styles object to keep the JSX clean
const styles = {
  logo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    marginTop: "15%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    border: "1px solid rgba(188, 177, 197, 0.3)",
    background: "#fcfbfe",
    marginLeft: "5%",
    marginRight: "5%",
  },
  header: {
    margin: "20px 0",
    color: "#6823D1",
    fontFamily: "Campton",
    fontSize: "24px",
    fontstyle: "normal",
    fontWight: "600",
    lineHeight: "22px" /* 137.5% */,
  },
  paragraph: {
    fontFamily: "Campton",
    fontSize: "16px",
    fontstyle: "normal",
    fontWeight: "400",
    lineHeight: "22px" /* 137.5% */,
    paddingLeft: "15px",
  },
  paragraph2: {
    fontFamily: "Campton",
    fontSize: "16px",
    fontstyle: "normal",
    fontWeight: "500",
    lineHeight: "22px" /* 137.5% */,
    paddingLeft: "15px",
    marginBottom: "10%",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#6823D1",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    width: "305px",
    height: "56px",
    fontFamily: "Campton",
    fontSize: "16px",
    fontstyle: "normal",
    fontWeight: "400",
    lineHeight: "22px" /* 137.5% */,
    marginTop: "10%",
  },
};

export default CompletedScreen;
